import React, { useContext, useState } from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import Condicional from '../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import ClsCrud from '../../Utils/ClsCrud'

import { StatusForm } from '../../Utils/ClsCrud'
import ClsValidacao from '../../Utils/ClsValidacao'
import { EMDESENVOLVIMENTO } from '../../ImportBackend/Config/emDesenvolvimento'
import { AnaliseGrupoInterface } from '../../ImportBackend/Interfaces/AnaliseInterfaces'
import SelectMultiplo from '../../DevComponents/SelectMultiplo'
import PesquisarTabela from '../../DevComponents/PesquisarTabela'
import { MaterialInterface } from '../../ImportBackend/Interfaces/MaterialInterfaces'

interface PesquisaInterface {
  descricao: string
}

export default function AnalisesGrupos () {

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )
  const [keyRefresh, setKeyRefresh] = useState<number>( 1 )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    }
  ]

  const ResetDados: AnaliseGrupoInterface = {
    idAnaliseGrupo: 0,
    descricao: '',
    Analises: [],
    idMaterial: 0,
    Determinacoes: []
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Novo Grupo de Análises',
    [StatusForm.Excluindo]: 'Exclusão de Grupo de Análises',
    [StatusForm.Pesquisando]: 'Grupo de Análises são utilizados em Tabela de Preços',
    [StatusForm.Editando]: 'Alteração de Dados de Grupo de Análises',
    [StatusForm.Exibindo]: 'Dados do Grupo de Análises'
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<AnaliseGrupoInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<AnaliseGrupoInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'descricao', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idMaterial', dados, erros, retorno )


    if ( dados.Analises.length === 0 && dados.Determinacoes.length === 0 ) {
      erros.Determinacoes = 'Selecione Determinacoes ou Análises!'
      erros.Analises = 'Selecione Determinacoes ou Análises!'
      retorno = false
    }

    setErros( erros )

    return retorno

  }

  const clsCrud: ClsCrud<AnaliseGrupoInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateAnaliseGrupo',
      excluirMutation: 'delAnaliseGrupo',
      campoId: 'idAnaliseGrupo',
      camposPesquisa: '{idAnaliseGrupo idMaterial descricao Analises {idAnalise} Determinacoes {idDeterminacao} }',
      pesquisaQuery: 'getAnaliseGrupo',
      pesquisaPorId: 'getAnaliseGrupoPorId',
      camposPesquisaPorId: '{idAnaliseGrupo idMaterial descricao Analises {idAnalise} Determinacoes {idDeterminacao} }'
    },
    {
      confirmando: 'Atualizando Grupo de Análise',
      erroCadastro: 'Erro ao Cadastrar Grupo de Análise',
      erroExclusao: 'Erro ao Excluir Grupo de Análise',
      erroPesquisa: 'Erro ao Pesquisar Grupo de Análise',
      pesquisando: 'Pesquisando Dados de Grupos de Análises...',
      sucessoCadastro: 'Grupo de Análise Cadastrado com sucesso!',
      atualizacaoSucesso: 'Grupo de Análise Atualizado com sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Grupo de Análise Excluído com sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Grupo de Análise...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  const resetDeterminacoesEAnalisesPorAlteracaoMaterial = ( v: MaterialInterface ) => {

    const idMaterial: number = v && v.idMaterial ? v.idMaterial : 0

    if ( idMaterial === 0 || !dados.idMaterial || dados.idMaterial !== v.idMaterial ) {
      setDados( { ...dados, Analises: [], Determinacoes: [], idMaterial: v && v.idMaterial ? v.idMaterial : 0 as number } )
    }

    if ( idMaterial ) {
      setKeyRefresh( keyRefresh + 1 )
    }

  }

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Grupo de Análise
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sm={10} sx={{ mb: 3 }}>

                <InputText
                  dados={pesquisa}
                  maxLength={50}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} sm={2} alignSelf='center' sx={{ mt: { xs: 0, sm: 2 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={() => clsCrud.btIncluir()}>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando}>

              <Grid item xs={12}>

                <InputText
                  dados={dados}
                  field='descricao'
                  label='Descrição'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={50}
                />

              </Grid>

              <Grid item xs={12}>

                <PesquisarTabela<MaterialInterface>
                  onChange={( v ) => resetDeterminacoesEAnalisesPorAlteracaoMaterial( v )}
                  setState={setDados}
                  field='idMaterial'
                  fieldSet='idMaterial'
                  label='Material'
                  dados={dados}
                  campoQueryPesquisaID='idMaterial'
                  campoQueryPesquisa='pesquisa'
                  camposRetornoQueryPesquisa='{idMaterial, descricao}'
                  campoLabelQueryPesquisa='descricao'
                  nomeQueryPesquisa='getMateriais'
                  nomeQueryPesquisaID='getMaterialPorId'
                  mensagemPesquisa='Procurando Materiais...'
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  pesquisarTudoAoIniciar
                />

              </Grid>

              <Grid item xs={12} >

                <SelectMultiplo
                  dados={dados}
                  fieldsRetornoObjeto={['idAnalise']}
                  field='Analises'
                  fieldIdSelect='idAnalise'
                  fieldSelect='descricao'
                  label='Análises'
                  setState={setDados}
                  erros={erros}
                  pesquisaInicial={{
                    mensagem: 'Pesquisando Análises',
                    tipo: 'Query',
                    pesquisa: `getAnalisesPorMaterial (idMaterial: ${dados.idMaterial}) {idAnalise, descricao}`,
                    objRetorno: 'getAnalisesPorMaterial'
                  }
                  }
                  key={keyRefresh}
                />

              </Grid>

              <Grid item xs={12} >

                <SelectMultiplo
                  dados={dados}
                  fieldsRetornoObjeto={['idDeterminacao']}
                  field='Determinacoes'
                  fieldIdSelect='idDeterminacao'
                  fieldSelect='nome'
                  label='Determinações'
                  setState={setDados}
                  erros={erros}
                  pesquisaInicial={{
                    mensagem: 'Pesquisando Determinações',
                    tipo: 'Query',
                    pesquisa: `getDeterminacoesPorMaterial (idMaterial: ${dados.idMaterial}) {idDeterminacao, nome}`,
                    objRetorno: 'getDeterminacoesPorMaterial'
                  }
                  }
                  key={keyRefresh * 100}
                />

              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={[
                  { icone: 'delete', toolTip: 'Excluir', onAcionador: clsCrud.btExcluir },
                  { icone: 'create', toolTip: 'Alterar', onAcionador: clsCrud.btEditar }]} />
              </Grid>
            </Condicional>

            <Condicional condicao={EMDESENVOLVIMENTO}>
              {JSON.stringify( dados )}
            </Condicional>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}