import { Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react'

import './ClassificacaoSoloTriangulo.css'
import ClsClassificacaoSolo, { CLASSFICACAO_SOLO_ENUM } from './ClsClassificacaoSolo';

interface PropsInterface {
  valorArgila: number
  valorSilte: number
  valorAreia: number
  tamanhoTriangulo?: number
}

enum CORES {
  MUITOARGILOSA = '#A67C52',
  ARGILA = '#8B4513',
  ARGILOARENOSA = '#D2B48C',
  FRANCOARGILOSA = '#DEB887',
  ARGILOSILTOSA = '#F4A460',
  FRANCOARGILOSILTOSA = '#CD853F',
  FRANCOARGILOARENOSA = '#BC8F8F',
  AREIA = '#DAA520',
  AREIAFRANCA = '#B8860B',
  FRANCOARENOSO = '#D2691E',
  FRANCA = '#A0522D',
  FRANCOSILTOSA = '#8B0000',
  SILTE = '#654321'
}

const COR_TEXTO_FILL = 'white'

export default function ClassificacaoSoloTriangulo ( { valorArgila = 0, valorSilte = 0, valorAreia = 0, tamanhoTriangulo = 600 }: PropsInterface ) {

  const canvasRef = useRef( null )

  // const FATOR_X: number = window.screen.height / window.screen.width;

  const ESPACAMENTO: number = tamanhoTriangulo / 2 / 10

  const drawPoint = ( context: CanvasRenderingContext2D, x: number, y: number, cor: string ) => {
    context.beginPath()
    context.strokeStyle = cor
    context.arc( x, y, 10, 0, 2 * Math.PI )
    context.stroke()
    context.closePath()

  }

  const drawLinha = ( context: CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number, cor: string, lineWidth: number = 2 ) => {
    // context.scale( FATOR_X, 1 )
    context.beginPath();
    context.lineWidth = lineWidth
    context.strokeStyle = cor
    context.moveTo( x1, y1 );
    context.lineTo( x2, y2 );
    context.stroke()
    context.closePath()
  }

  const drawText = ( context: CanvasRenderingContext2D, texto: string, x: number, y: number, tamanho: number, cor: string ) => {
    context.beginPath()
    context.fillStyle = cor
    context.font = "".concat( tamanho.toString().trim(), "px Arial" )
    context.fillText( texto, x - context.measureText( texto ).width / 2, y + 10 )
    context.closePath()
  }

  const drawShape = ( context: CanvasRenderingContext2D, texto: Array<{ x: number, y: number, texto: string, tamanho: number }>, points: Array<[number, number]>, fill: boolean, cor: string, lineWidth: number = 2 ) => {

    if ( points.length >= 2 ) {

      context.beginPath();
      context.lineWidth = lineWidth
      context.strokeStyle = cor
      context.fillStyle = cor
      context.moveTo( points[0][0], points[0][1] );

      points.forEach( ( point, index ) => {

        if ( index > 0 ) {
          context.lineTo( point[0], point[1] );
        }

      } )

      context.stroke()

      if ( fill ) context.fill()

      context.closePath()

      context.beginPath()
      context.fillStyle = fill ? COR_TEXTO_FILL : cor

      texto.forEach( ( texto ) => {
        context.font = "bold ".concat( texto.tamanho.toString().trim(), "px Arial" );
        context.fillText( texto.texto, texto.x - context.measureText( texto.texto ).width / 2, texto.y + 10 );
      } )

      context.closePath()

    }

  }

  // Draw das Classificações Individuais

  const drawMuitoArgilosa = ( context: CanvasRenderingContext2D, classificacao: Array<string> ) => {

    const fill: boolean = classificacao.includes( CLASSFICACAO_SOLO_ENUM.MUITOARGILOSA )

    drawShape( context, [{ texto: 'Muito Argilosa', x: tamanhoTriangulo / 2, y: ESPACAMENTO * 5, tamanho: 15 }],
      [
        [tamanhoTriangulo / 2, 0],
        [tamanhoTriangulo / 2 + ESPACAMENTO * 4, ESPACAMENTO * 8],
        [tamanhoTriangulo / 2 - ESPACAMENTO * 4, ESPACAMENTO * 8],
        [tamanhoTriangulo / 2, 0]],
      fill, CORES.MUITOARGILOSA, 2 )

  }

  const drawArgila = ( context: CanvasRenderingContext2D, classificacao: Array<string> ) => {

    const fill: boolean = classificacao.includes( CLASSFICACAO_SOLO_ENUM.ARGILA )

    drawShape( context, [{ texto: 'Argila', x: tamanhoTriangulo / 2.1, y: ESPACAMENTO * 9.5, tamanho: 15 }],
      [[tamanhoTriangulo / 2 - ESPACAMENTO * 4, ESPACAMENTO * 8],
      [tamanhoTriangulo / 2 + ESPACAMENTO * 4, ESPACAMENTO * 8],
      [tamanhoTriangulo / 2 + ESPACAMENTO * 2, ESPACAMENTO * 12],
      [tamanhoTriangulo / 2 - ESPACAMENTO * 3, ESPACAMENTO * 12],
      [tamanhoTriangulo / 2 - ESPACAMENTO * 4.5, ESPACAMENTO * 9]],
      fill, CORES.ARGILA, 2 )

  }

  const drawArgiloSiltosa = ( context: CanvasRenderingContext2D, classificacao: Array<string> ) => {

    const fill: boolean = classificacao.includes( CLASSFICACAO_SOLO_ENUM.ARGILOSILTOSA )

    drawShape( context,
      [
        { texto: 'Argilo', x: tamanhoTriangulo / 1.43, y: ESPACAMENTO * 10.3, tamanho: 15 },
        { texto: 'Siltosa', x: tamanhoTriangulo / 1.43, y: ESPACAMENTO * 11, tamanho: 15 }
      ],
      [
        [tamanhoTriangulo / 2 + ESPACAMENTO * 4, ESPACAMENTO * 8],
        [tamanhoTriangulo / 2 + ESPACAMENTO * 2, ESPACAMENTO * 12],
        [tamanhoTriangulo / 2 + ESPACAMENTO * 6, ESPACAMENTO * 12]
      ],
      fill, CORES.ARGILOSILTOSA, 2 )

  }

  const drawArgiloArenosa = ( context: CanvasRenderingContext2D, classificacao: Array<string> ) => {

    const fill: boolean = classificacao.includes( CLASSFICACAO_SOLO_ENUM.ARGILOARENOSA )

    drawShape( context,
      [
        { texto: 'Argilo', x: ESPACAMENTO * 5.5, y: ESPACAMENTO * 11.2, tamanho: 15 },
        { texto: 'Arenosa', x: ESPACAMENTO * 5.5, y: ESPACAMENTO * 11.9, tamanho: 15 }
      ],
      [
        [tamanhoTriangulo / 2 - ESPACAMENTO * 4.5, ESPACAMENTO * 9],
        [ESPACAMENTO * 7.5, ESPACAMENTO * 13],
        [ESPACAMENTO * 3.5, ESPACAMENTO * 13]
      ],
      fill, CORES.ARGILOARENOSA, 2 )

  }

  const drawFrancoArgilosa = ( context: CanvasRenderingContext2D, classificacao: Array<string> ) => {

    const fill: boolean = classificacao.includes( CLASSFICACAO_SOLO_ENUM.FRANCOARGILOSA )

    drawShape( context,
      [
        { texto: 'Franco', x: tamanhoTriangulo / 2, y: ESPACAMENTO * 12.5, tamanho: 15 },
        { texto: 'Argilosa', x: tamanhoTriangulo / 2, y: ESPACAMENTO * 13.2, tamanho: 15 }
      ],
      [
        [tamanhoTriangulo / 2 + ESPACAMENTO * 2, ESPACAMENTO * 12],
        [tamanhoTriangulo / 2 - ESPACAMENTO * 3, ESPACAMENTO * 12],
        [ESPACAMENTO * 8.5, tamanhoTriangulo - ESPACAMENTO * 5.5],
        [ESPACAMENTO * 13.25, tamanhoTriangulo - ESPACAMENTO * 5.5]
      ],
      fill, CORES.FRANCOARGILOSA, 2 )

  }

  const drawFrancoArgiloSiltosa = ( context: CanvasRenderingContext2D, classificacao: Array<string> ) => {

    const fill: boolean = classificacao.includes( CLASSFICACAO_SOLO_ENUM.FRANCOARGILOSILTOSA )

    drawShape( context,
      [
        { texto: 'Franco', x: ESPACAMENTO * 14.3, y: ESPACAMENTO * 12.5, tamanho: 15 },
        { texto: 'Argilo', x: ESPACAMENTO * 14.4, y: ESPACAMENTO * 13.1, tamanho: 15 },
        { texto: 'Siltosa', x: ESPACAMENTO * 14.6, y: ESPACAMENTO * 13.7, tamanho: 15 }
      ],
      [
        [tamanhoTriangulo / 2 + ESPACAMENTO * 2, ESPACAMENTO * 12],
        [tamanhoTriangulo / 2 + ESPACAMENTO * 6, ESPACAMENTO * 12],
        [ESPACAMENTO * 17.25, tamanhoTriangulo - ESPACAMENTO * 5.5],
        [ESPACAMENTO * 13.25, tamanhoTriangulo - ESPACAMENTO * 5.5]
      ],
      fill, CORES.FRANCOARGILOSILTOSA, 2 )

  }

  const drawFrancoArgiloArenosa = ( context: CanvasRenderingContext2D, classificacao: Array<string> ) => {

    const fill: boolean = classificacao.includes( CLASSFICACAO_SOLO_ENUM.FRANCOARGILOARENOSA )

    drawShape( context,
      [
        { texto: 'Franco', x: ESPACAMENTO * 5.2, y: ESPACAMENTO * 13.5, tamanho: 15 },
        { texto: 'Argilo', x: ESPACAMENTO * 5.4, y: ESPACAMENTO * 14.1, tamanho: 15 },
        { texto: 'Arenosa', x: ESPACAMENTO * 5.8, y: ESPACAMENTO * 14.7, tamanho: 15 }
      ],
      [
        [ESPACAMENTO * 3.5, ESPACAMENTO * 13],
        [ESPACAMENTO * 7.5, ESPACAMENTO * 13],
        [ESPACAMENTO * 8.5, ESPACAMENTO * 14.5],
        [ESPACAMENTO * 7.5, ESPACAMENTO * 16],
        [ESPACAMENTO * 2, ESPACAMENTO * 16]
      ],
      fill, CORES.FRANCOARGILOARENOSA, 2 )

  }

  /*
  const drawLimits = ( context: CanvasRenderingContext2D, classificacao: Array<string> ) => {
     drawPoint( context, tamanhoTriangulo / 2 - ESPACAMENTO * 4, ESPACAMENTO * 8, 'green' )
     drawPoint( context, tamanhoTriangulo / 2 + ESPACAMENTO * 4, ESPACAMENTO * 8, 'yellow' )
     drawPoint( context, tamanhoTriangulo / 2 + ESPACAMENTO * 2, ESPACAMENTO * 12, 'blue' )
     drawPoint( context, tamanhoTriangulo / 2 - ESPACAMENTO * 4.5, ESPACAMENTO * 9, 'Lime' )
     drawPoint( context, tamanhoTriangulo / 2 + ESPACAMENTO * 6, ESPACAMENTO * 12, 'cyan' )


    // Franco Argiloso Arenoso
     drawPoint( context, ESPACAMENTO * 3.5, ESPACAMENTO * 13, 'Teal' )
     drawPoint( context, ESPACAMENTO * 7.5, ESPACAMENTO * 13, 'black' )
     drawPoint( context, ESPACAMENTO * 8.5, ESPACAMENTO * 14.5, 'Fuchsia' )
     drawPoint( context, ESPACAMENTO * 7.5, ESPACAMENTO * 16, 'Fuchsia' )
     drawPoint( context, ESPACAMENTO * 2, ESPACAMENTO * 16, 'Fuchsia' )

    // Franca
     drawPoint( context, ESPACAMENTO * 8.5, ESPACAMENTO * 14.5, 'black' )
     drawPoint( context, ESPACAMENTO * 12.75, ESPACAMENTO * 14.5, 'black' )
     drawPoint( context, ESPACAMENTO * 10.5, ESPACAMENTO * 19, 'black' )
     drawPoint( context, ESPACAMENTO * 9, ESPACAMENTO * 19, 'black' )
     drawPoint( context, ESPACAMENTO * 7.5, ESPACAMENTO * 16, 'black' )

    // FrancoSiltosa
     drawPoint( context, ESPACAMENTO * 12.75, ESPACAMENTO * 14.5, 'black' )
     drawPoint( context, ESPACAMENTO * 10, ESPACAMENTO * 20, 'black' )
     drawPoint( context, ESPACAMENTO * 16, ESPACAMENTO * 20, 'black' )
     drawPoint( context, ESPACAMENTO * 17, ESPACAMENTO * 18, 'black' )
     drawPoint( context, ESPACAMENTO * 19, ESPACAMENTO * 18, 'black' )
     drawPoint( context, ESPACAMENTO * 17.25, ESPACAMENTO * 14.5, 'black' )

    // Silte
     drawPoint( context, ESPACAMENTO * 16, ESPACAMENTO * 20, 'black' )
     drawPoint( context, ESPACAMENTO * 17, ESPACAMENTO * 18, 'black' )
     drawPoint( context, ESPACAMENTO * 19, ESPACAMENTO * 18, 'black' )
     drawPoint( context, ESPACAMENTO * 20, ESPACAMENTO * 20, 'black' )

    // Franco Arenoso
     drawPoint( context, ESPACAMENTO * 6, ESPACAMENTO * 20, 'red' )
     drawPoint( context, ESPACAMENTO * 1.5, ESPACAMENTO * 17, 'black' )
     drawPoint( context, ESPACAMENTO * 2, ESPACAMENTO * 16, 'black' )
     drawPoint( context, ESPACAMENTO * 7.5, ESPACAMENTO * 16, 'black' )
     drawPoint( context, ESPACAMENTO * 9, ESPACAMENTO * 19, 'black' )
     drawPoint( context, ESPACAMENTO * 10.5, ESPACAMENTO * 19, 'black' )
     drawPoint( context, ESPACAMENTO * 10, ESPACAMENTO * 20, 'black' )

    //Areia Franca
     drawPoint( context, ESPACAMENTO * 6, ESPACAMENTO * 20, 'red' )
     drawPoint( context, ESPACAMENTO * 1.5, ESPACAMENTO * 17, 'black' )
     drawPoint( context, ESPACAMENTO, ESPACAMENTO * 18, 'black' )
     drawPoint( context, ESPACAMENTO * 3, ESPACAMENTO * 20, 'black' )

    // Areia
     drawPoint( context, ESPACAMENTO * 3, ESPACAMENTO * 20, 'black' )
     drawPoint( context, ESPACAMENTO, ESPACAMENTO * 18, 'black' )
     drawPoint( context, 0, ESPACAMENTO * 20, 'black' )

    // drawPoint( context, ESPACAMENTO * 13.25, ESPACAMENTO * 14.5, 'Navy' )
    // drawPoint( context, ESPACAMENTO * 17.25, ESPACAMENTO * 14.5, 'Navy' )

    // drawPoint( context, 0, tamanhoTriangulo, 'black' )
  }
    */

  const drawFranca = ( context: CanvasRenderingContext2D, classificacao: Array<string> ) => {

    const fill: boolean = classificacao.includes( CLASSFICACAO_SOLO_ENUM.FRANCA )

    drawShape( context,
      [
        { texto: 'Franca', x: ESPACAMENTO * 10, y: ESPACAMENTO * 16, tamanho: 15 }
      ],
      [
        [ESPACAMENTO * 8.5, ESPACAMENTO * 14.5],
        [ESPACAMENTO * 12.75, ESPACAMENTO * 14.5],
        [ESPACAMENTO * 10.5, ESPACAMENTO * 19],
        [ESPACAMENTO * 9, ESPACAMENTO * 19],
        [ESPACAMENTO * 7.5, ESPACAMENTO * 16]
      ],
      fill, CORES.FRANCA, 2 )

  }

  const drawFrancoSiltosa = ( context: CanvasRenderingContext2D, classificacao: Array<string> ) => {

    const fill: boolean = classificacao.includes( CLASSFICACAO_SOLO_ENUM.FRANCOSILTOSA )

    drawShape( context,
      [
        { texto: 'Franco', x: ESPACAMENTO * 14.5, y: ESPACAMENTO * 16, tamanho: 15 },
        { texto: 'Siltosa', x: ESPACAMENTO * 14.5, y: ESPACAMENTO * 17, tamanho: 15 }
      ],
      [
        [ESPACAMENTO * 12.75, ESPACAMENTO * 14.5],
        [ESPACAMENTO * 10, ESPACAMENTO * 20],
        [ESPACAMENTO * 16, ESPACAMENTO * 20],
        [ESPACAMENTO * 17, ESPACAMENTO * 18],
        [ESPACAMENTO * 19, ESPACAMENTO * 18],
        [ESPACAMENTO * 17.25, ESPACAMENTO * 14.5]

      ],
      fill, CORES.FRANCOSILTOSA, 2 )

  }

  const drawSilte = ( context: CanvasRenderingContext2D, classificacao: Array<string> ) => {

    const fill: boolean = classificacao.includes( CLASSFICACAO_SOLO_ENUM.SILTE )

    drawShape( context,
      [
        { texto: 'Silte', x: ESPACAMENTO * 18, y: ESPACAMENTO * 19, tamanho: 15 }
      ],
      [
        [ESPACAMENTO * 16, ESPACAMENTO * 20],
        [ESPACAMENTO * 17, ESPACAMENTO * 18],
        [ESPACAMENTO * 19, ESPACAMENTO * 18],
        [ESPACAMENTO * 20, ESPACAMENTO * 20]
      ],
      fill, CORES.SILTE, 2 )

  }

  const drawFrancoArenoso = ( context: CanvasRenderingContext2D, classificacao: Array<string> ) => {

    const fill: boolean = classificacao.includes( CLASSFICACAO_SOLO_ENUM.FRANCOARENOSO )

    drawShape( context,
      [
        { texto: 'Franco', x: ESPACAMENTO * 6, y: ESPACAMENTO * 17.5, tamanho: 15 },
        { texto: 'Arenoso', x: ESPACAMENTO * 7, y: ESPACAMENTO * 18.5, tamanho: 15 }
      ],
      [
        [ESPACAMENTO * 6, ESPACAMENTO * 20],
        [ESPACAMENTO * 1.5, ESPACAMENTO * 17],
        [ESPACAMENTO * 2, ESPACAMENTO * 16],
        [ESPACAMENTO * 7.5, ESPACAMENTO * 16],
        [ESPACAMENTO * 9, ESPACAMENTO * 19],
        [ESPACAMENTO * 10.5, ESPACAMENTO * 19],
        [ESPACAMENTO * 10, ESPACAMENTO * 20]
      ],
      fill, CORES.FRANCOARENOSO, 2 )

  }

  const drawAreiaFranca = ( context: CanvasRenderingContext2D, classificacao: Array<string> ) => {

    const fill: boolean = classificacao.includes( CLASSFICACAO_SOLO_ENUM.AREIAFRANCA )

    drawShape( context,
      [
        { texto: 'Areia', x: ESPACAMENTO * 2.25, y: ESPACAMENTO * 18, tamanho: 15 },
        { texto: 'Franca', x: ESPACAMENTO * 3.25, y: ESPACAMENTO * 18.75, tamanho: 15 }
      ],
      [
        [ESPACAMENTO * 6, ESPACAMENTO * 20],
        [ESPACAMENTO * 1.5, ESPACAMENTO * 17],
        [ESPACAMENTO, ESPACAMENTO * 18],
        [ESPACAMENTO * 3, ESPACAMENTO * 20]
      ],
      fill, CORES.AREIAFRANCA, 2 )

  }

  const drawAreia = ( context: CanvasRenderingContext2D, classificacao: Array<string> ) => {

    const fill: boolean = classificacao.includes( CLASSFICACAO_SOLO_ENUM.AREIA )

    drawShape( context,
      [
        { texto: 'Areia', x: ESPACAMENTO * 1.25, y: ESPACAMENTO * 19, tamanho: 15 }
      ],
      [
        [ESPACAMENTO * 3, ESPACAMENTO * 20],
        [ESPACAMENTO, ESPACAMENTO * 18],
        [0, ESPACAMENTO * 20]
      ],
      fill, CORES.AREIA, 2 )

  }

  useEffect( () => {

    const draw = ( context: CanvasRenderingContext2D ) => {
      // Triangulo Principal

      context.clearRect( 0, 0, tamanhoTriangulo, tamanhoTriangulo )

      // Silte
      drawLinha( context, tamanhoTriangulo / 2, 0, tamanhoTriangulo, tamanhoTriangulo, CORES.SILTE )
      drawText( context, '100', ESPACAMENTO * 20.5, ESPACAMENTO * 19.50, 12, CORES.SILTE )
      drawText( context, '0', ESPACAMENTO * 10.5, 0, 12, CORES.SILTE )
      drawText( context, '% S I L T E', ESPACAMENTO * 18, tamanhoTriangulo / 2, 16, CORES.SILTE )


      // Areia
      drawLinha( context, tamanhoTriangulo, tamanhoTriangulo, 0, tamanhoTriangulo, CORES.AREIA )
      drawText( context, '100', 10, tamanhoTriangulo + 7, 12, CORES.AREIA )
      drawText( context, '0', tamanhoTriangulo, tamanhoTriangulo + 7, 12, CORES.AREIA )
      drawText( context, '% A R E I A', tamanhoTriangulo / 2, tamanhoTriangulo + 25, 16, CORES.AREIA )

      //Argila
      drawLinha( context, 0, tamanhoTriangulo, tamanhoTriangulo / 2, 0, CORES.ARGILA )
      drawText( context, '100', ESPACAMENTO * 9.25, 0, 12, CORES.ARGILA )
      drawText( context, '0', 2, ESPACAMENTO * 19.25, 12, CORES.ARGILA )
      drawText( context, '% A R G I L A', ESPACAMENTO * 2, tamanhoTriangulo / 2, 16, CORES.ARGILA )

      let percentualCrescente: number = 10
      let percentualDeCrescente: number = 90

      // Habilita Linha Tracejada para indicadores dos percentuais
      context.setLineDash( [1, 3] )

      for ( let contador: number = ESPACAMENTO; contador < tamanhoTriangulo / 2; contador += ESPACAMENTO ) {

        // Silte
        drawLinha( context, tamanhoTriangulo - contador * 2, tamanhoTriangulo, tamanhoTriangulo - contador, tamanhoTriangulo - contador * 2, CORES.SILTE, 1 )
        // Legenda Silte
        drawText( context, percentualCrescente.toString().trim(), ESPACAMENTO * 10.5 + contador, contador * 1.95, 12, CORES.SILTE )


        // Areia
        drawLinha( context, tamanhoTriangulo / 2 - contador, contador * 2, tamanhoTriangulo - contador * 2, tamanhoTriangulo, CORES.AREIA, 1 )
        // Legenda Areia
        drawText( context, percentualDeCrescente.toString().trim(), contador * 2, tamanhoTriangulo + 7, 12, CORES.AREIA )


        // Argila
        drawLinha( context, contador, tamanhoTriangulo - contador * 2, tamanhoTriangulo - contador, tamanhoTriangulo - contador * 2, 'blue', 1 )
        // Legenda Argila
        drawText( context, percentualCrescente.toString().trim(), contador - 17.5, tamanhoTriangulo - contador * 2, 12, CORES.ARGILA )

        percentualCrescente += 10
        percentualDeCrescente -= 10

      }

      // Desabilita Linha Tracejada para indicadores dos percentuais
      context.setLineDash( [] )

    }


    const canvas: HTMLCanvasElement = canvasRef.current as any as HTMLCanvasElement
    const context: CanvasRenderingContext2D = canvas.getContext( '2d' ) as CanvasRenderingContext2D

    /*
    canvas.addEventListener( 'click', ( event ) => {
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      alert( `Coordenadas do clique: x=${x}, y=${y}` );
    } );
     */

    const classificacaoSolo: Array<string> = ( new ClsClassificacaoSolo() ).classificarSolo(
      { valorAreia: valorAreia, valorArgila: valorArgila, valorSilte: valorSilte }
    )

    //Our draw come here
    draw( context )
    drawMuitoArgilosa( context, classificacaoSolo )
    drawArgila( context, classificacaoSolo )
    drawArgiloSiltosa( context, classificacaoSolo )
    drawArgiloArenosa( context, classificacaoSolo )
    drawFrancoArgilosa( context, classificacaoSolo )
    drawFrancoArgiloSiltosa( context, classificacaoSolo )
    drawFrancoArgiloArenosa( context, classificacaoSolo )
    drawFranca( context, classificacaoSolo )
    drawFrancoSiltosa( context, classificacaoSolo )
    drawSilte( context, classificacaoSolo )
    drawFrancoArenoso( context, classificacaoSolo )
    drawAreiaFranca( context, classificacaoSolo )
    drawAreia( context, classificacaoSolo )
  }, [valorAreia, valorAreia, valorSilte] )

  return (
    <canvas width={tamanhoTriangulo + 50} height={tamanhoTriangulo + 50} className='clsCanvaTriangulo' ref={canvasRef} />
    /*
   
      existeClassificacao = existeClassificacao || atualizarElemento( "divARGILA", valorArgila > 40 && valorArgila < 60 && valorSilte < 40 && valorAreia < 45 )
      existeClassificacao = existeClassificacao || atualizarElemento( "divMUITOARGILOSA", valorArgila >= 60 && valorSilte < 40 && valorAreia < 40 )
      existeClassificacao = existeClassificacao || atualizarElemento( "divARGILOSILTOSA", valorArgila >= 40 && valorSilte >= 40 )
      existeClassificacao = existeClassificacao || atualizarElemento( "divARGILOARENOSA", valorArgila > 35 && valorAreia >= 45 )
      existeClassificacao = existeClassificacao || atualizarElemento( "divFRANCOARGILOSA", valorArgila > 27 && valorArgila < 40 && valorAreia > 20 && valorAreia < 45 )
      existeClassificacao = existeClassificacao || atualizarElemento( "divFRANCOARGILOARENOSA", valorArgila >= 20 && valorArgila <= 35 && valorSilte <= 28 && valorAreia >= 45 )
      existeClassificacao = existeClassificacao || atualizarElemento( "divFRANCOARGILOSILTOSA", valorArgila >= 27 && valorArgila <= 40 && valorAreia <= 20 )
      existeClassificacao = existeClassificacao || atualizarElemento( "divFRANCOSILTOSA", valorArgila >= 12 && valorArgila < 27 && valorSilte >= 50 )
      existeClassificacao = existeClassificacao || atualizarElemento( "divFRANCOSILTOSA2", valorArgila <= 12 && valorSilte >= 50 && valorSilte <= 80 )
      existeClassificacao = existeClassificacao || atualizarElemento( "divSILTE", valorArgila < 12 && valorSilte > 80 )
      existeClassificacao = existeClassificacao || atualizarElemento( "divFRANCA", valorArgila >= 7 && valorArgila <= 27 && valorSilte >= 28 && valorSilte < 50 && valorAreia < 52 )
      existeClassificacao = existeClassificacao || atualizarElemento( "divFRANCOARENOSO", valorArgila < 7 && valorSilte < 50 && valorAreia < 52 && valorAreia > 43 )
      existeClassificacao = existeClassificacao || atualizarElemento( "divFRANCOARENOSO2", valorArgila < 20 && valorAreia >= 52 && valorSilte + 2 * valorArgila > 30 )
      existeClassificacao = existeClassificacao || atualizarElemento( "divAREIAFRANCA", valorSilte + 2 * valorArgila <= 30 && valorAreia >= 70 && valorAreia < 85 )
      existeClassificacao = existeClassificacao || atualizarElemento( "divAREIAFRANCA2", valorSilte + 1.5 * valorArgila > 15 && valorAreia >= 85 && valorAreia <= 90 )
      existeClassificacao = existeClassificacao || atualizarElemento( "divAREIA", ( valorSilte + 1.5 * valorArgila ) <= 15 && valorAreia >= 85 )
  */

  )

}