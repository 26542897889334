import React, { useState } from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import ExibirJSONDev from '../../DevComponents/ExibirJSONDev'
import { EMDESENVOLVIMENTO } from '../../ImportBackend/Config/emDesenvolvimento'
import ShowText from '../../DevComponents/ShowText'

interface ClassificacaoInterface {
  areiaGrossa: number
  areiaFina: number
  silte: number
  argila: number
  classeAD: string
  ADPredita_mm_por_cm: number
  tipoZARC: string
}

interface CalssificacaoZARCInterface {
  classeAD: string
  ADPredita_mm_por_cm: number
}

export default function ClassificacaoZARC () {

  const calcularTipoSoloZARC = ( argila: number, areia: number ): string => {

    const diferenca = Math.abs( areia - argila );

    let tipo: string = "Solo não classificado"

    if ( diferenca >= 50 && argila >= 10 ) {
      tipo = "Solo Tipo 1 (Textura Arenosa)";
    } else if ( diferenca < 50 && argila >= 15 && argila < 35 ) {
      tipo = "Solo Tipo 2 (Textura Média)";
    } else if ( argila >= 35 ) {
      tipo = "Solo Tipo 3 (Textura Argilosa)";
    }

    return tipo

  }

  const calcularZARC = ( areia: number, argila: number, silte: number ): CalssificacaoZARCInterface => {

    let retorno: CalssificacaoZARCInterface = {
      ADPredita_mm_por_cm: 0,
      classeAD: 'Não é possível Classfificar'
    }

    const ad = parseFloat( ( ( ( 1 + ( 0.3591 * ( ( -0.02128887 * areia ) + ( -0.01005814 * silte ) + ( -0.01901894 * argila ) + ( 0.0001171219 * areia * silte ) + ( 0.0002073924 * areia * argila ) + ( 0.00006118707 * silte * argila ) + ( -0.000006373789 * areia * silte * argila ) ) ) ) ** 2.78474 ) * 10 ).toFixed( 2 ) )

    if ( ( silte >= 0 ) ) {
      if ( ad >= 0.00 && ad < 0.33 ) {
        retorno.classeAD = 'AD0'
      } else if ( ad >= 0.34 && ad < 0.46 ) {
        retorno.classeAD = 'AD1'
      } else if ( ad >= 0.46 && ad < 0.61 ) {
        retorno.classeAD = 'AD2'
      } else if ( ad >= 0.61 && ad < 0.80 ) {
        retorno.classeAD = 'AD3'
      } else if ( ad >= 0.80 && ad < 1.06 ) {
        retorno.classeAD = 'AD4'
      } else if ( ad >= 1.06 && ad < 1.40 ) {
        retorno.classeAD = 'AD5'
      } else if ( ad >= 1.40 ) {
        retorno.classeAD = 'AD6'
      }

      retorno.ADPredita_mm_por_cm = parseFloat( ad.toFixed( 2 ) )

    } else {

      retorno.classeAD = "'Soma de variáveis diferente de 100%.'"

    }

    return retorno

  }


  const [dados, setDados] = useState<ClassificacaoInterface>( {
    areiaGrossa: 0,
    areiaFina: 0,
    silte: 0,
    argila: 0,
    classeAD: '',
    ADPredita_mm_por_cm: 0,
    tipoZARC: ''
  } )

  const alteracao = ( campo: string, valor: string ) => {
    let tmpDados = { ...dados, [campo]: parseFloat( valor ) }
    tmpDados.silte = 100 - ( tmpDados.areiaGrossa + tmpDados.areiaFina + tmpDados.argila )
    const rsZARC = calcularZARC( tmpDados.areiaGrossa + tmpDados.areiaFina, tmpDados.argila, tmpDados.silte )
    setDados( { ...tmpDados, ...rsZARC, tipoZARC: calcularTipoSoloZARC( tmpDados.argila, tmpDados.areiaGrossa + tmpDados.areiaFina ) } )
  }

  return (
    <>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <Grid item xs={10} sm={8} md={8} lg={6}>
          <Paper>
            <Box
              sx={{ backgroundColor: 'primary.main', padding: 2 }}
              textAlign='center'
            >
              <img src={process.env.REACT_APP_PASTA?.concat( "/logoFundoBranco.png" )} width={150} alt={process.env.REACT_APP_TITULO} />
            </Box>
            <Box sx={{ backgroundColor: 'white', padding: 2, mx: 5 }}            >
              <Grid container>

                <Grid item textAlign='center' xs={12} sx={{ mb: 2 }}>
                  <Typography variant="h4" fontFamily='sans-serif' fontWeight='bolder' color="primary.main">
                    Classificação ZARC
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputText
                    dados={dados}
                    field='areiaGrossa'
                    label='Areia Grossa'
                    tipo='currency'
                    textAlign='right'
                    onChange={( e: string ) => alteracao( 'areiaGrossa', e )}
                  />
                </Grid>

                <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>
                  <InputText
                    dados={dados}
                    field='areiaFina'
                    label='Areia Fina'
                    tipo='currency'
                    textAlign='right'
                    onChange={( e: string ) => alteracao( 'areiaFina', e )}
                  />
                </Grid>

                <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>
                  <InputText
                    dados={dados}
                    field='argila'
                    label='Argila'
                    tipo='currency'
                    textAlign='right'
                    onChange={( e: string ) => alteracao( 'argila', e )}
                  />
                </Grid>

                <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>
                  <InputText
                    dados={dados}
                    field='silte'
                    label='Silte'
                    tipo='currency'
                    textAlign='right'
                    disabled
                  />
                </Grid>

                {/*
                <Grid item xs={12} textAlign='right'>
                  <Button variant='contained' onClick={() => exibirCalssificacaoSolo()} sx={{ mt: 5 }}>Classificar</Button>
                </Grid>
                */}

                <Grid item xs={4} textAlign='center' sx={{ mt: 5, mb: 5 }}>
                  <ShowText label='Classe AD' field='classeAD' dados={dados} />
                </Grid>

                <Grid item xs={4} textAlign='center' sx={{ mt: 5, mb: 5, pl: 1 }}>
                  <ShowText label='AD Predita mm/cm' field='ADPredita_mm_por_cm' dados={dados} />
                </Grid>

                <Grid item xs={4} textAlign='center' sx={{ mt: 5, mb: 5, pl: 1 }}>
                  <ShowText label='Tipo ZARC' field='tipoZARC' dados={dados} />
                </Grid>

              </Grid>

            </Box>

          </Paper>

          <ExibirJSONDev oque={['Dados', dados]} exibir={EMDESENVOLVIMENTO} />

        </Grid>


      </Grid >
    </>
  )

}