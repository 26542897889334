export enum CLASSFICACAO_SOLO_ENUM {
  ARGILA = 'Argila',
  MUITOARGILOSA = 'Muito Argilosa',
  ARGILOSILTOSA = 'Argilo Siltosa',
  ARGILOARENOSA = 'Argilo Arenosa',
  FRANCOARGILOSA = 'Franco Argilosa',
  FRANCOARGILOARENOSA = 'Franco Argilo Arenosa',
  FRANCOARGILOSILTOSA = 'Franco Argilo Siltosa',
  FRANCOSILTOSA = 'Franco Siltosa',
  SILTE = 'Silte',
  FRANCA = 'Franca',
  FRANCOARENOSO = 'Franco Arenoso',
  AREIAFRANCA = 'Areia Franca',
  AREIA = 'Areia'
}

interface PropsInterface {
  valorArgila: number
  valorSilte: number
  valorAreia: number
}

export default class ClsClassificacaoSolo {

  /*
atualizarElemento( "divARGILA", valorArgila > 40 && valorArgila < 60 && valorSilte < 40 && valorAreia < 45 )
atualizarElemento( "divMUITOARGILOSA", valorArgila >= 60 && valorSilte < 40 && valorAreia < 40 )
atualizarElemento( "divARGILOSILTOSA", valorArgila >= 40 && valorSilte >= 40 )
atualizarElemento( "divARGILOARENOSA", valorArgila > 35 && valorAreia >= 45 )
atualizarElemento( "divFRANCOARGILOSA", valorArgila > 27 && valorArgila < 40 && valorAreia > 20 && valorAreia < 45 )
atualizarElemento( "divFRANCOARGILOARENOSA", valorArgila >= 20 && valorArgila <= 35 && valorSilte <= 28 && valorAreia >= 45 )
atualizarElemento( "divFRANCOARGILOSILTOSA", valorArgila >= 27 && valorArgila <= 40 && valorAreia <= 20 )
atualizarElemento( "divFRANCOSILTOSA", valorArgila >= 12 && valorArgila < 27 && valorSilte >= 50 )
atualizarElemento( "divFRANCOSILTOSA2", valorArgila <= 12 && valorSilte >= 50 && valorSilte <= 80 )
atualizarElemento( "divSILTE", valorArgila < 12 && valorSilte > 80 )
atualizarElemento( "divFRANCA", valorArgila >= 7 && valorArgila <= 27 && valorSilte >= 28 && valorSilte < 50 && valorAreia < 52 )
atualizarElemento( "divFRANCOARENOSO", valorArgila < 7 && valorSilte < 50 && valorAreia < 52 && valorAreia > 43 )
atualizarElemento( "divFRANCOARENOSO2", valorArgila < 20 && valorAreia >= 52 && valorSilte + 2 * valorArgila > 30 )
atualizarElemento( "divAREIAFRANCA", valorSilte + 2 * valorArgila <= 30 && valorAreia >= 70 && valorAreia < 85 )
atualizarElemento( "divAREIAFRANCA2", valorSilte + 1.5 * valorArgila > 15 && valorAreia >= 85 && valorAreia <= 90 )
atualizarElemento( "divAREIA", ( valorSilte + 1.5 * valorArgila ) <= 15 && valorAreia >= 85 )
  */

  public classificarSolo ( { valorAreia, valorArgila, valorSilte }: PropsInterface ): Array<string> {
    let retorno: Array<string> = []

    // if ( valorArgila > 40 && valorArgila < 60 && valorSilte < 40 && valorAreia < 45 ) {
    if ( valorArgila >= 40 && valorArgila <= 60 && valorSilte <= 40 && valorAreia <= 45 ) {
      retorno.push( CLASSFICACAO_SOLO_ENUM.ARGILA )
    }

    // if ( valorArgila >= 60 && valorSilte < 40 && valorAreia < 40 ) {
    if ( valorArgila >= 60 && valorSilte <= 40 && valorAreia <= 40 ) {
      retorno.push( CLASSFICACAO_SOLO_ENUM.MUITOARGILOSA )
    }

    if ( valorArgila >= 40 && valorSilte >= 40 ) {
      retorno.push( CLASSFICACAO_SOLO_ENUM.ARGILOSILTOSA )
    }

    // if ( valorArgila > 35 && valorAreia >= 45 ) {
    if ( valorArgila >= 35 && valorAreia >= 45 ) {
      retorno.push( CLASSFICACAO_SOLO_ENUM.ARGILOARENOSA )
    }

    // if ( valorArgila > 27 && valorArgila < 40 && valorAreia > 20 && valorAreia < 45 ) {
    if ( valorArgila >= 27 && valorArgila <= 40 && valorAreia >= 20 && valorAreia <= 45 ) {
      retorno.push( CLASSFICACAO_SOLO_ENUM.FRANCOARGILOSA )
    }

    if ( valorArgila >= 20 && valorArgila <= 35 && valorSilte <= 28 && valorAreia >= 45 ) {
      retorno.push( CLASSFICACAO_SOLO_ENUM.FRANCOARGILOARENOSA )
    }

    if ( valorArgila >= 27 && valorArgila <= 40 && valorAreia <= 20 ) {
      retorno.push( CLASSFICACAO_SOLO_ENUM.FRANCOARGILOSILTOSA )
    }

    // if ( valorArgila >= 12 && valorArgila < 27 && valorSilte >= 50 ) {
    if ( valorArgila >= 12 && valorArgila <= 27 && valorSilte >= 50 ) {
      retorno.push( CLASSFICACAO_SOLO_ENUM.FRANCOSILTOSA )
    }

    if ( valorArgila <= 12 && valorSilte >= 50 && valorSilte <= 80 ) {
      retorno.push( CLASSFICACAO_SOLO_ENUM.FRANCOSILTOSA )
    }

    // if ( valorArgila < 12 && valorSilte > 80 ) {
    if ( valorArgila <= 12 && valorSilte >= 80 ) {
      retorno.push( CLASSFICACAO_SOLO_ENUM.SILTE )
    }

    // if ( valorArgila >= 7 && valorArgila <= 27 && valorSilte >= 28 && valorSilte < 50 && valorAreia < 52 ) {
    if ( valorArgila >= 7 && valorArgila <= 27 && valorSilte >= 28 && valorSilte <= 50 && valorAreia <= 52 ) {
      retorno.push( CLASSFICACAO_SOLO_ENUM.FRANCA )
    }

    // if ( valorArgila < 7 && valorSilte < 50 && valorAreia < 52 && valorAreia > 43 ) {
    if ( valorArgila <= 7 && valorSilte <= 50 && valorAreia <= 52 && valorAreia >= 43 ) {
      retorno.push( CLASSFICACAO_SOLO_ENUM.FRANCOARENOSO )
    }

    // if ( valorArgila < 20 && valorAreia >= 52 && valorSilte + 2 * valorArgila > 30 ) {
    if ( valorArgila <= 20 && valorAreia >= 52 && ( valorSilte + 2 * valorArgila ) >= 30 ) {
      retorno.push( CLASSFICACAO_SOLO_ENUM.FRANCOARENOSO )
    }

    // if ( valorSilte + 2 * valorArgila <= 30 && valorAreia >= 70 && valorAreia < 85 ) {
    if ( ( valorSilte + 2 * valorArgila ) <= 30 && valorAreia >= 70 && valorAreia <= 85 ) {
      retorno.push( CLASSFICACAO_SOLO_ENUM.AREIAFRANCA )
    }

    // if ( valorSilte + 1.5 * valorArgila > 15 && valorAreia >= 85 && valorAreia <= 90 ) {
    if ( ( valorSilte + 1.5 * valorArgila ) >= 15 && valorAreia >= 85 && valorAreia <= 90 ) {
      retorno.push( CLASSFICACAO_SOLO_ENUM.AREIAFRANCA )
    }

    if ( ( valorSilte + 1.5 * valorArgila ) <= 15 && valorAreia >= 85 ) {
      retorno.push( CLASSFICACAO_SOLO_ENUM.AREIA )
    }

    return retorno
  }
}