import React, { useContext, useState, useEffect } from 'react'

import { Container, Grid, IconButton, Paper, Typography } from '@mui/material'

import { MaterialInterface } from '../../../ImportBackend/Interfaces/MaterialInterfaces'
import { DeterminacaoInterface } from '../../../ImportBackend/Interfaces/DeterminacaoInterfaces'
import BackEndAPI from '../../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../../GlobalStates/MensagemState'
import { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'

import ClsUtils from 'zlib-utils'
import { RespostaPadraoInterface } from '../../../ImportBackend/Interfaces/PadraoInterfaces'
import DataTableCrud from '../../../DevComponents/DataTableCrud'
import MateriaisDeterminacoesForm from './MateriaisDeterminacoesForm'

import CloseIcon from '@mui/icons-material/Close'

interface propsInterface {
  rsMaterial: MaterialInterface | null
  onClose: () => void
}

export enum StatusForm {
  Incluindo,
  Excluindo,
  Exibindo,
  Editando,
  Inicial
}

export default function MateriaisDeterminacoes ( { rsMaterial, onClose }: propsInterface ) {

  const clsApi: BackEndAPI = new BackEndAPI()

  const clsUtils: ClsUtils = new ClsUtils()

  const abortController: AbortController = new AbortController()

  const contexto: ContextoGlobalInterface = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )
  const { mensagemState, setMensagemState } = contexto

  const ResetDados: DeterminacaoInterface = {
    idDeterminacao: 0,
    nome: '',
    simbolo: '',
    idMaterial: 0,
    chave: '',
    cabecalhoLaudo: '',
    ordem: 0,
    calculo: [],
    idTipoMapaProducao: 0,
    leituraPadraoPorBandeja: false,
    idUnidadeDeterminacao: 0,
    parametros: [],
    laudo: true,
    laboratorio: true,
    padraoAleatorioPorBandeja: false,
    permitirValorSemFormula: false,
    novaPaginaLaudo: false
  }

  const [rsDeterminacoes, setRsDeterminacoes] = useState<Array<DeterminacaoInterface>>( [] )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'nome',
      cabecalho: 'Nome',
      alinhamento: 'left'
    },
    {
      campo: 'simbolo',
      cabecalho: 'Símbolo',
      alinhamento: 'left'
    },
    {
      campo: 'UnidadeDeterminacao',
      cabecalho: 'Unidade',
      alinhamento: 'left',
      format: ( rs ) => rs.unidade
    },
    {
      campo: 'chave',
      cabecalho: 'Chave',
      alinhamento: 'left'
    },
    {
      campo: 'cabecalhoLaudo',
      cabecalho: 'Cabeçalho laudo',
      alinhamento: 'left'
    }
  ]

  const pesquisarDeterminacoesDoMaterial = () => {
    const query = `
      getDeterminacoesPorMaterial(idMaterial: ${rsMaterial?.idMaterial}) {
        idDeterminacao
        nome
        simbolo
        idMaterial
        chave
        cabecalhoLaudo
        ordem
        leituraPadraoPorBandeja
        padraoAleatorioPorBandeja
        idUnidadeDeterminacao
        UnidadeDeterminacao {
          unidade
        }
        idTipoMapaProducao
        laudo
        laboratorio
        permitirValorSemFormula
        novaPaginaLaudo
        parametros {
          descricao
          tipoInformacao
          conteudo
          variavel
        }
        calculo {
          descricao
          tipoInformacao
          conteudo
          variavel
        }
      }
    `

    clsApi.query<Array<DeterminacaoInterface>>( query, 'getDeterminacoesPorMaterial', 'Recebendo Materiais...', contexto, abortController ).then( rsDeterminacoes => {

      setRsDeterminacoes( rsDeterminacoes )

    } ).catch( () => {

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Consultar Determinacoes de Materiais!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  // Pesquisa Determinacoes de acordo ao idMaterial
  useEffect( () => {
    pesquisarDeterminacoesDoMaterial()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const updateDeterminacao = (
    rs: DeterminacaoInterface,
    mensagemAPI: string,
    tituloSucesso: string,
    mensagemSucesso: string,
    tituloErro: string,
    mensagemErro: string
  ) => {

    rs.idMaterial = rsMaterial?.idMaterial as number

    //@ts-ignore
    delete rs.UnidadeDeterminacao

    const mutation: string = `
        updateMaterialDeterminacao (dados: ${clsUtils.ConverterEmGql( rs )}) {
          ok
          mensagem
        }
      `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'updateMaterialDeterminacao', mensagemAPI, contexto ).then( rs => {

      if ( rs.ok ) {
        setMensagemState( {
          ...mensagemState,
          titulo: tituloSucesso,
          exibir: true,
          mensagem: mensagemSucesso,
          tipo: MensagemTipo.Info,
          exibirBotao: true
        } )

        pesquisarDeterminacoesDoMaterial()

      } else {

        throw new Error()

      }

    } ).catch( () => {

      setMensagemState( {
        ...mensagemState,
        titulo: tituloErro,
        exibir: true,
        mensagem: mensagemErro,
        tipo: MensagemTipo.Info,
        exibirBotao: true
      } )

    } )

  }

  const onIncluir = ( rs: DeterminacaoInterface ) => {
    updateDeterminacao(
      rs,
      'Incluindo Novo Determinacao...',
      'Novo Determinacao!',
      'Novo Determinacao Cadastrado com sucesso!',
      'Erro ao Cadastrar!',
      'Novo Determinacao Não foi Criado!'
    )
  }

  const onExcluir = ( rs: DeterminacaoInterface ) => {
    const mutation: string = `
        delMaterialDeterminacao (idDeterminacao: ${rs.idDeterminacao}) {
          ok
          mensagem
        }
      `

    clsApi.mutationRespostaPadrao( mutation, 'delMaterialDeterminacao', 'Excluindo Determinacao...', contexto ).then( rs => {

      if ( rs.ok ) {
        pesquisarDeterminacoesDoMaterial()
      }

    } )

  }

  const onAlterar = ( rs: DeterminacaoInterface ) => {
    updateDeterminacao(
      rs,
      'Alterando Determinacao...',
      'Dados Alterados!',
      'Dados de Determinacao Atualizados com sucesso!',
      'Erro ao Atualizar!',
      'Dados Não Foram Alterados!'
    )
  }

  const onDragAndDrop = ( indiceDrag: number, indiceDrop: number, rsDrag: DeterminacaoInterface, rsDrop: DeterminacaoInterface ) => {
    const mutation: string = `
        alterarOrdemMaterialDeterminacao (idOrigem: ${rsDrag.idDeterminacao}, idDestino: ${rsDrop.idDeterminacao}) {
          ok
          mensagem
        }
      `

    clsApi.mutationRespostaPadrao( mutation, 'alterarOrdemMaterialDeterminacao', 'Alterando Ordem', contexto ).then( rs => {

      if ( rs.ok ) {
        pesquisarDeterminacoesDoMaterial()
      }

    } )

  }

  return (
    <>

      <Container maxWidth="lg" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>

          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Typography component="h5" variant="h5" align="left">
              Determinações do Material
              <Typography variant="body2" gutterBottom>
                Cadastro de Determinacoes
              </Typography>
            </Typography>

            <IconButton onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid item xs={12}>
            <DataTableCrud<DeterminacaoInterface>
              dadosIniciaisRegistro={ResetDados}
              cabecalho={Cabecalho}
              dados={rsDeterminacoes}
              ComponenteInputCrud={MateriaisDeterminacoesForm}
              tituloCrud='Determinações da Análise'
              onIncluir={onIncluir}
              onExcluir={onExcluir}
              onAlterar={onAlterar}
              onDragAndDrop={onDragAndDrop}
            />
          </Grid>
        </Paper>
      </Container>
    </>
  )

}