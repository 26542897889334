import React, { useState } from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import ClassificacaoSoloTriangulo from './ClassificacaoSoloTriangulo'

interface ClassificacaoInterface {
  areiaGrossa: number
  areiaFina: number
  silte: number
  argila: number
}

export default function ClassificacaoSolo () {

  const [dados, setDados] = useState<ClassificacaoInterface>( {
    areiaGrossa: 0,
    areiaFina: 0,
    silte: 0,
    argila: 0
  } )

  const alteracao = ( campo: string, valor: string ) => {
    let tmpDados = { ...dados, [campo]: parseFloat( valor ) }
    tmpDados.silte = 100 - ( tmpDados.areiaGrossa + tmpDados.areiaFina + tmpDados.argila )
    setDados( tmpDados )
  }

  return (
    <>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <Grid item xs={10} sm={8} md={8} lg={6}>
          <Paper>
            <Box
              sx={{ backgroundColor: 'primary.main', padding: 2 }}
              textAlign='center'
            >
              <img src={process.env.REACT_APP_PASTA?.concat( "/logoFundoBranco.png" )} width={150} alt={process.env.REACT_APP_TITULO} />
            </Box>
            <Box sx={{ backgroundColor: 'white', padding: 2, mx: 5 }}            >
              <Grid container>

                <Grid item textAlign='center' xs={12} sx={{ mb: 2 }}>
                  <Typography variant="h4" fontFamily='sans-serif' fontWeight='bolder' color="primary.main">
                    Classificação Textural do Solo
                  </Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputText
                    dados={dados}
                    field='areiaGrossa'
                    label='Areia Grossa'
                    tipo='currency'
                    textAlign='right'
                    onChange={( e: string ) => alteracao( 'areiaGrossa', e )}
                  />
                </Grid>

                <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>
                  <InputText
                    dados={dados}
                    field='areiaFina'
                    label='Areia Fina'
                    tipo='currency'
                    textAlign='right'
                    onChange={( e: string ) => alteracao( 'areiaFina', e )}
                  />
                </Grid>

                <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>
                  <InputText
                    dados={dados}
                    field='argila'
                    label='Argila'
                    tipo='currency'
                    textAlign='right'
                    onChange={( e: string ) => alteracao( 'argila', e )}
                  />
                </Grid>

                <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>
                  <InputText
                    dados={dados}
                    field='silte'
                    label='Silte'
                    tipo='currency'
                    textAlign='right'
                    disabled
                  />
                </Grid>

                {/*
                <Grid item xs={12} textAlign='right'>
                  <Button variant='contained' onClick={() => exibirCalssificacaoSolo()} sx={{ mt: 5 }}>Classificar</Button>
                </Grid>
                */}

                <Grid item xs={12} textAlign='center' sx={{ mt: 5, mb: 5 }}>
                  <ClassificacaoSoloTriangulo valorAreia={dados.areiaFina + dados.areiaGrossa} valorArgila={dados.argila} valorSilte={dados.silte} />
                </Grid>

              </Grid>

            </Box>

          </Paper>
        </Grid>
      </Grid >
    </>
  )

}