import React, { useContext, useEffect, useState } from 'react'

import { Box, Button, Dialog, Grid, IconButton, Paper, Typography } from '@mui/material'
import DataTableCrudJSON, { PropsInterfaceDetalheJSON, StatusForm } from '../../../DevComponents/DataTableCrudJSON'

import ClsValidacao from '../../../Utils/ClsValidacao'

import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import InputText from '../../../DevComponents/InputText'
import Condicional from '../../../Layout/Condicional'
import { DeterminacaoCalculoInterface, DeterminacaoInterface } from '../../../ImportBackend/Interfaces/DeterminacaoInterfaces'
import { TipoInformacaoDeterminacaoType } from '../../../ImportBackend/types/ConstantesDataTypes'
import { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import MateriaisDeterminacoesCalculo from './MateriaisDeterminacoesCalculo'
import { EMDESENVOLVIMENTO } from '../../../ImportBackend/Config/emDesenvolvimento'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'
import PesquisarTabela from '../../../DevComponents/PesquisarTabela'
import { TipoMapaProducaoInterface } from '../../../ImportBackend/Interfaces/TipoMapaProducaoInterfaces'
import { pt_br } from '../../../Config/TradutorJodit'
import BackEndAPI from '../../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../../GlobalStates/MensagemState'
import ComboBox from '../../../DevComponents/ComboBox'

export const CONFIG_EDITOR_UNIDADE_DESCRICAO = {
  buttons: ['subscript', 'superscript', 'symbols'],
  limitChars: 15,
  // language: 'pt_br',
  spellcheck: true,
  disablePlugins: ['spellcheck', 'speechRecognize'],
  i18n: {
    pt_br: pt_br
  },
  statusbar: false,
  removeButtons: [
    'alignCenter',
    'paragraph',
    'outdent',
    'indent',
    'brush',
    'lineHeight',
    'redo',
    'undo',
    'image',
    'video',
    'about',
    'file',
    'ul',
    'ol',
    'link',
    'fullsize',
    'print',
    'about',
    'font',
    'fontsize',
    ''
  ],
  /*
  removeButtons: ['image', 'video', 'about', 'file',
    'source',
    'ul',
    'ol',
    'outdent',
    'indent',
    'brush',
    'paragraph',
    'image',
    'video',
    'table',
    'link',
    'align',
    'undo',
    'redo',
    'hr',
    'eraser',
    'copyformat',
    'fullsize',
    'print',
    'about',
    'dots'
  ],
  */
  placeholder: 'Símbolo para impressão'
}

export default function MateriaisDeterminacoesForm ( {
  onConfirmarExclusao,
  onConfirmarEdicaoInclusao,
  onCancelar,
  open,
  statusForm,
  registro,
  titulo
}: PropsInterfaceDetalheJSON<DeterminacaoInterface> ) {

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Novo Determinacao',
    [StatusForm.Excluindo]: 'Exclusão de Determinacao',
    [StatusForm.Exibindo]: 'Determinacao',
    [StatusForm.Editando]: 'Alteração de Dados de Determinacao',
    [StatusForm.Inicial]: 'Dados de Determinacoes',
  }

  const [dados, setDados] = useState<DeterminacaoInterface>( { ...registro } )

  useEffect( () => {
    setDados( { ...registro } )
    setDadosCalculo( registro.calculo )
    setDadosParametros( registro.parametros )
  }, [registro] )

  const [erros, setErros] = useState( {} )

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'chave', dados, erros, retorno )

    if ( retorno ) {
      clsValidacao.validarComRegEx( /^[A-Za-z0-9_]+$/, 'chave', dados, erros, retorno, true, 'Permitido letras, números e underScore!' )
    }

    retorno = clsValidacao.naoVazio( 'nome', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idTipoMapaProducao', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idUnidadeDeterminacao', dados, erros, retorno )

    // 
    // retorno = clsValidacao.naoVazio( 'calculo', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const btConfirmar = () => {

    if ( validarDados() ) {
      onConfirmarEdicaoInclusao( dados )
    }

  }

  // Determinacoes Calculo JSON
  const [dadosCalculo, setDadosCalculo] = useState<Array<DeterminacaoCalculoInterface>>( [] )

  // Determinacoes Parametros JSON
  const [dadosParametros, setDadosParametros] = useState<Array<DeterminacaoCalculoInterface>>( [] )

  const resetDados: DeterminacaoCalculoInterface = {
    descricao: '',
    tipoInformacao: TipoInformacaoDeterminacaoType.ENTRADA,
    conteudo: '',
    variavel: ''
  }

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    },
    {
      campo: 'tipoInformacao',
      cabecalho: 'Tipo da Informação',
      alinhamento: 'left'
    },
    {
      campo: 'conteudo',
      cabecalho: 'Conteúdo',
      alinhamento: 'left'
    },
    {
      campo: 'variavel',
      cabecalho: 'Variável',
      alinhamento: 'left'
    }
  ]

  const onChangeMateriaisDeterminacoesCalculos = ( rs: Array<DeterminacaoCalculoInterface> ) => {
    // console.log( 'On Change....' )
    setDados( { ...dados, calculo: rs } )
  }

  const onChangeMateriaisDeterminacoesParametros = ( rs: Array<DeterminacaoCalculoInterface> ) => {
    setDados( { ...dados, parametros: rs } )
  }

  const clsApi: BackEndAPI = new BackEndAPI()

  const [rsTiposMapaProducao, setRsTiposMapaProducao] = useState<Array<TipoMapaProducaoInterface>>( [] )

  const abortController: AbortController = new AbortController()
  const contexto: ContextoGlobalInterface = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )
  const { mensagemState, setMensagemState } = contexto

  const loadTipoMapaProducao = () => {

    const query = `
      getTiposMapaProducaoPorMaterial(idMaterial: ${registro.idMaterial}) {
        idTipoMapaProducao
        descricao
        qtdAmostrasPorBandeja
        idSetorProducao
      }
    `

    clsApi.query<Array<TipoMapaProducaoInterface>>( query, 'getTiposMapaProducaoPorMaterial', 'Recebendo Tipos de Mapa de Produção...', contexto, abortController ).then( rsTiposMapa => {

      setRsTiposMapaProducao( rsTiposMapa )

    } ).catch( () => {

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Consultar Tipos de Mapa de Produção!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  useEffect( () => loadTipoMapaProducao(), [] )

  return (
    <Dialog open={open} onClose={onCancelar}>

      <Paper variant="outlined" sx={{ padding: 2, margin: 2 }}>

        <Grid container>

          <Grid item xs={12}>

            <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Typography component="h5" variant="h5" align="left">
                  {titulo}
                  <Typography variant="body2" gutterBottom>
                    {TituloForm[statusForm]}
                  </Typography>
                </Typography>

                <IconButton onClick={() => onCancelar()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>

          </Grid>

          <Grid item xs={12}>

            <InputText
              dados={dados}
              field='nome'
              label='Nome'
              maxLength={50}
              setState={setDados}
              disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}
              erros={erros}
            />

          </Grid>
          {/*

          <Grid item xs={12}>

            <Typography variant="body2" sx={{ mt: 3 }} gutterBottom>
              Símbolo para Laudo
            </Typography>

            <JoditEditor
              value={dados.simbolo}
              onBlur={( rs ) => { setDados( { ...dados, simbolo: rs } ) }}
              config={CONFIG_EDITOR_UNIDADE_DESCRICAO}
            />

          </Grid>

            */}

          <Grid item xs={12}>

            <InputText
              dados={dados}
              field='simbolo'
              label='Símbolo'
              maxLength={255}
              setState={setDados}
              disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}
              erros={erros}
            />

          </Grid>

          <Grid item xs={12}>

            <InputText
              dados={dados}
              field='chave'
              label='Chave'
              maxLength={25}
              setState={setDados}
              disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}
              erros={erros}
            />

          </Grid>

          <Grid item xs={12}>

            <InputText
              dados={dados}
              field='cabecalhoLaudo'
              label='Seção / Cabeçalho laudo'
              maxLength={255}
              setState={setDados}
              disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}
              erros={erros}
            />

          </Grid>

          <Grid item xs={12}>

            <ComboBox<TipoMapaProducaoInterface>
              dados={dados}
              campoDescricao='descricao'
              campoID='idTipoMapaProducao'
              field='idTipoMapaProducao'
              label='Tipo Mapa de Produção'
              opcoes={rsTiposMapaProducao}
              setState={setDados}
            />

          </Grid>

          <Grid item xs={12}>

            <PesquisarTabela<TipoMapaProducaoInterface>
              setState={setDados}
              field='idUnidadeDeterminacao'
              fieldSet='idUnidadeDeterminacao'
              label='Unidade Padrão'
              dados={dados}
              campoQueryPesquisaID='idUnidadeDeterminacao'
              campoQueryPesquisa='pesquisa'
              camposRetornoQueryPesquisa='{idUnidadeDeterminacao, unidade}'
              campoLabelQueryPesquisa='unidade'
              nomeQueryPesquisa='getUnidadesDeterminacao'
              nomeQueryPesquisaID='getUnidadeDeterminacaoPorId'
              mensagemPesquisa='Procurando Unidades de Determinação...'
              disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}
              erros={erros}
              pesquisarTudoAoIniciar
            />

          </Grid>

          <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>

            <Box sx={{ display: 'flex' }}>

              <InputText
                dados={dados}
                field='laboratorio'
                label='Laboratório'
                setState={setDados}
                disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}
                erros={erros}
                tipo='checkbox'
              />

              <InputText
                dados={dados}
                field='leituraPadraoPorBandeja'
                label='Ler Padrão'
                setState={setDados}
                disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm ) || !dados.laboratorio}
                erros={erros}
                tipo='checkbox'
              />

              <InputText
                dados={dados}
                field='padraoAleatorioPorBandeja'
                label='Padrão Aleatório Por Bandeja'
                setState={setDados}
                disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm ) || !dados.laboratorio}
                erros={erros}
                tipo='checkbox'
              />

            </Box>

          </Grid>

          <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>

            <Box sx={{ display: 'flex' }}>

              <InputText
                dados={dados}
                field='laudo'
                label='Laudo'
                setState={setDados}
                disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}
                erros={erros}
                tipo='checkbox'
              />

              <InputText
                dados={dados}
                field='novaPaginaLaudo'
                label='Nova Página no Laudo'
                setState={setDados}
                disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}
                erros={erros}
                tipo='checkbox'
              />

              <InputText
                dados={dados}
                field='permitirValorSemFormula'
                label='Permitir Adcionar Valor Sem Fórmula'
                setState={setDados}
                disabled={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}
                erros={erros}
                tipo='checkbox'
              />

            </Box>

          </Grid>

          <Condicional condicao={dados.laboratorio}>
            <Grid item xs={12}>
              <DataTableCrudJSON<DeterminacaoCalculoInterface>
                dadosIniciaisRegistro={resetDados}
                cabecalho={Cabecalho}
                dados={dadosParametros}
                ComponenteInputCrud={MateriaisDeterminacoesCalculo}
                setState={setDadosParametros}
                tituloCrud='Parâmetros da Determinacao'
                onChange={onChangeMateriaisDeterminacoesParametros}
              />
            </Grid>
          </Condicional>

          <Grid item xs={12}>
            <DataTableCrudJSON<DeterminacaoCalculoInterface>
              dadosIniciaisRegistro={resetDados}
              cabecalho={Cabecalho}
              dados={dadosCalculo}
              ComponenteInputCrud={MateriaisDeterminacoesCalculo}
              setState={setDadosCalculo}
              tituloCrud='Cálculo da Determinacao'
              onChange={onChangeMateriaisDeterminacoesCalculos}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>

            <Condicional condicao={![StatusForm.Editando, StatusForm.Incluindo].includes( statusForm )}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => onConfirmarExclusao()}>Confirmar</Button>
            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Excluindo}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmar()}>Confirmar</Button>
            </Condicional>

            <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => onCancelar()}>Cancelar</Button>

          </Grid>

        </Grid>
      </Paper>


      <ExibirJSONDev exibir={EMDESENVOLVIMENTO} oque={dadosCalculo} />

    </Dialog>
  )

}