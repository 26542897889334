import React, { useContext, useEffect, useRef, useState } from 'react'
import LeituraDataTable, { DataTableCabecalhoLeituraInterface } from './LeituraDataTable'
import BackEndAPI from '../../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import { DeterminacaoCalculoInterface, DeterminacaoInterface } from '../../../ImportBackend/Interfaces/DeterminacaoInterfaces'
import { ID_AMOSTR_PADRAO, StatusAmostraType, TipoInformacaoDeterminacaoType, UnidadeDeterminacaoTipoInformacaoType } from '../../../ImportBackend/types/ConstantesDataTypes'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'
import { EMDESENVOLVIMENTO } from '../../../ImportBackend/Config/emDesenvolvimento'
import { UnidadeDeterminacaoInterface } from '../../../ImportBackend/Interfaces/UnidadeDeterminacaoInterfaces'
import { LeituraInterface, LeituraPadraoInterface, LeituraPadraoRealizadaInterface, LeituraRealizadaInterface, rsAmostraParaLeituraInterface } from '../../../ImportBackend/Interfaces/LeituraInterfaces'
import ClsFormatacao from '../../../Utils/ClsFormatacao'
import LeituraParametros from './LeituraParametros'
import { Grid, IconButton } from '@mui/material'

import HelpIcon from '@mui/icons-material/Help'
import HelpTeclasLeiura from './HelpTeclasLeitura'

const LARGURA_MINIMA_INPUT_VALOR: number = 10

export interface LeituraPropsInterface {
  idMapaProducao: number
  idDeterminacao: number
  bandeja: number
  onClose: () => void
}

export default function Leitura (
  { idMapaProducao,
    idDeterminacao,
    onClose,
    bandeja
  }: LeituraPropsInterface ) {

  const clsApi: BackEndAPI = new BackEndAPI()

  const ResetRsDeterminacao: DeterminacaoInterface = {
    calculo: [],
    chave: '',
    cabecalhoLaudo: '',
    idMaterial: 0,
    idTipoMapaProducao: 0,
    idUnidadeDeterminacao: 0,
    laboratorio: true,
    laudo: true,
    leituraPadraoPorBandeja: true,
    nome: '',
    ordem: 0,
    padraoAleatorioPorBandeja: true,
    parametros: [],
    simbolo: '',
    permitirValorSemFormula: false,
    novaPaginaLaudo: false
  }

  const contexto: ContextoGlobalInterface = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )
  // const { mensagemState, setMensagemState } = contexto

  const rsColunasEditaveis = useRef<Array<number>>( [] )

  const rsParametros = useRef<Record<string, number>>( {} )

  const rsDeterminacaoInicial = useRef<DeterminacaoInterface>( ResetRsDeterminacao )
  const rsUnidadeDeterminacaoInicial = useRef<string>( '' )

  const [rsAmostrasParaLeitura, setRsAmostrasParaLeitura] = useState<Array<rsAmostraParaLeituraInterface>>( [] )
  const [rsDeterminacao, setRsDeterminacao] = useState<DeterminacaoInterface>( ResetRsDeterminacao )
  const [rsUnidadeDeterminacao, setRsUnidadeDeterminacao] = useState<UnidadeDeterminacaoInterface>( {
    casasDecimais: 0,
    conversoes: [],
    tipoInformacao: UnidadeDeterminacaoTipoInformacaoType.NUMERO,
    unidade: '',
    descricaoLaudo: ''
  } )

  const clsFormatacao = new ClsFormatacao()

  const abortController: AbortController = new AbortController()

  /**
   * Cabeçalho das colunas das amostras que serão exibidas. É acrescido com os campos editáveis da determinação.
   */
  const CABECALHO_INICIAL: Array<DataTableCabecalhoLeituraInterface> = [
    {
      cabecalho: 'Amostra',
      campo: 'numeroAmostra',
      alinhamento: 'left',
      edit: false,
      largura: 1
    },
    /*
        {
          cabecalho: 'Identificacao 01',
          campo: 'identificacao01',
          alinhamento: 'left',
          edit: false,
          largura: 1
        },
        {
          cabecalho: 'Identificacao 02',
          campo: 'identificacao02',
          alinhamento: 'left',
          edit: false,
          largura: 1
        },
        {
          cabecalho: 'Identificacao 03',
          campo: 'identificacao03',
          alinhamento: 'left',
          edit: false,
          largura: 1
        },
        {
          cabecalho: 'Identificacao 04',
          campo: 'identificacao04',
          alinhamento: 'left',
          edit: false,
          largura: 1
        },
        {
          cabecalho: 'Observação',
          campo: 'observacao',
          alinhamento: 'left',
          edit: false,
          largura: 1
        },
        */
    {
      cabecalho: 'Observação Determinação',
      campo: 'observacaoDeterminacao',
      alinhamento: 'left',
      edit: false,
      largura: 1
    }
  ]

  const [cabecalho, setCabecalho] = useState<Array<DataTableCabecalhoLeituraInterface>>( [] )

  const pesquisarAmostras = () => {

    if ( idMapaProducao > 0 ) {

      const query: string = `
        getAmostrasPorMapaProducaoDeterminacao(idMapaProducao: ${idMapaProducao}, idDeterminacao: ${idDeterminacao}) {
          idAmostra
          numeroAmostra
          identificacao01
          identificacao02
          identificacao03
          identificacao04
          observacao
          observacaoDeterminacao
          status
          realizarLeitura
          edicao
          calculoExecutadoComSucesso
          leiturasRealizadas {
            idLeituraRealizada
            dataHoraLeitura
            idAmostra
            idDeterminacao
            idMapaProducao
            idUsuario
            leitura
          }
          leitura {
            idLeitura
            idAmostra
            idDeterminacao
            digitacao
            idUnidadeDeterminacao
            unidadeResultado
            resultado
          }
        }
      `

      clsApi.query<Array<rsAmostraParaLeituraInterface>>( query, 'getAmostrasPorMapaProducaoDeterminacao', 'Pesquisando Amostras...', contexto, abortController ).then( ( rsAmostrasParaLeitura ) => {

        // TODO - Colocar aqui a amostra padrão.....
        incluirAmostraPadrao( rsAmostrasParaLeitura ).then( rsAmostrasParaLeitura => {

          rsAmostrasParaLeitura.forEach( ( _rs, indiceAmostrasParaLeitura ) => {
            if ( rsAmostrasParaLeitura[indiceAmostrasParaLeitura].leitura ) {

              if ( typeof rsAmostrasParaLeitura[indiceAmostrasParaLeitura].leitura.digitacao === 'string' ) {
                rsAmostrasParaLeitura[indiceAmostrasParaLeitura].leitura.digitacao = JSON.parse( rsAmostrasParaLeitura[indiceAmostrasParaLeitura].leitura.digitacao as unknown as string )
              }

              rsAmostrasParaLeitura[indiceAmostrasParaLeitura].leiturasRealizadas.forEach( ( _rs, indiceLeiturasRealizadas ) => {
                if ( typeof rsAmostrasParaLeitura[indiceAmostrasParaLeitura].leiturasRealizadas[indiceLeiturasRealizadas].leitura === 'string' ) {
                  rsAmostrasParaLeitura[indiceAmostrasParaLeitura].leiturasRealizadas[indiceLeiturasRealizadas].leitura = JSON.parse( rsAmostrasParaLeitura[indiceAmostrasParaLeitura].leiturasRealizadas[indiceLeiturasRealizadas].leitura as unknown as string )
                }
              } )

            } else {
              // Caso não possua leitura prévia, assumir os dados de conteudoInicial caso exista
              // console.log( 'dentro do else....', rsAmostrasParaLeitura[indiceAmostrasParaLeitura], rsDeterminacaoInicial.current.calculo )

              rsDeterminacaoInicial.current.calculo.forEach( rsCalculo => {

                if ( rsCalculo.conteudoInicial && rsCalculo.conteudoInicial.length > 0 && rsCalculo.conteudoInicial.length - 1 >= indiceAmostrasParaLeitura ) {

                  const rsParametroLeituraLaboratorio: Record<string, number> = rsCalculo.conteudoInicial[indiceAmostrasParaLeitura] as any

                  rsAmostrasParaLeitura[indiceAmostrasParaLeitura].leitura = {
                    digitacao: {
                      [rsCalculo.variavel]: rsParametroLeituraLaboratorio.valor,
                      ['ID_'.concat( rsCalculo.variavel )]: rsParametroLeituraLaboratorio.chave
                    },
                    idAmostra: rsAmostrasParaLeitura[indiceAmostrasParaLeitura].idAmostra,
                    idDeterminacao: rsDeterminacaoInicial.current.idDeterminacao as number,
                    idUnidadeDeterminacao: rsDeterminacaoInicial.current.idUnidadeDeterminacao,
                    resultado: '',
                    unidadeResultado: rsUnidadeDeterminacaoInicial.current

                  }
                  // console.log( rsAmostrasParaLeitura[indiceAmostrasParaLeitura].leitura.digitacao )
                }

              } )

            }

          } )

          setRsAmostrasParaLeitura( rsAmostrasParaLeitura )

        } )

      } )

      return () => {

        abortController.abort()

      }

    } else {

      // setRsAmostras( [] )

    }

  }

  const incluirAmostraPadrao = ( rsAmostrasParaLeitura: Array<rsAmostraParaLeituraInterface> ): Promise<Array<rsAmostraParaLeituraInterface>> => {

    const querySqlLeituraPadrao: string = `
      leituraPadrao(idMapaProducao: ${idMapaProducao}, idDeterminacao: ${idDeterminacao}) {
        idLeitura
        idAmostra
        idDeterminacao
        digitacao
        idUnidadeDeterminacao
        unidadeResultado
        resultado
      }
    `

    const querySqlLeituraPadraoRealizada: string = `
      leiturasPadraoRealizadas(idMapaProducao: ${idMapaProducao}, idDeterminacao: ${idDeterminacao}) {
        idLeituraRealizada
        dataHoraLeitura
        idAmostra
        idDeterminacao
        idMapaProducao
        idUsuario
        leitura
      }
    `

    return clsApi.query<LeituraInterface>( querySqlLeituraPadrao, 'leituraPadrao', 'Pesquisando Leitura Padrao...', contexto, abortController ).then( ( rsLeituraPadrao ) => {

      return clsApi.query<Array<LeituraRealizadaInterface>>( querySqlLeituraPadraoRealizada, 'leiturasPadraoRealizadas', 'Pesquisando Leitura Padrao Realizada...', contexto, abortController ).then( ( rsLeituraPadraoRealizada ) => {

        let retorno: Array<rsAmostraParaLeituraInterface> = JSON.parse( JSON.stringify( rsAmostrasParaLeitura ) )

        const leituraPadrao: rsAmostraParaLeituraInterface = {
          idAmostra: ID_AMOSTR_PADRAO,
          numeroAmostra: ID_AMOSTR_PADRAO,
          identificacao01: '',
          identificacao02: '',
          identificacao03: '',
          identificacao04: '',
          observacao: '',
          observacaoDeterminacao: '',
          status: StatusAmostraType.PRODUCAO_PARCIAL,
          realizarLeitura: true,
          edicao: true,
          calculoExecutadoComSucesso: false,
          leiturasRealizadas: rsLeituraPadraoRealizada,
          leitura: rsLeituraPadrao
        }

        // console.log( 'Amostras Para leitura: ', retorno )

        if ( rsDeterminacao.leituraPadraoPorBandeja ) {

          let indice: number = 0

          if ( rsDeterminacao.padraoAleatorioPorBandeja ) {
            if ( retorno.length <= ( bandeja - 1 ) ) {
              indice = bandeja - 1
            }
          }

          // console.log( 'Bandeja: ', bandeja, indice )

          retorno.splice( indice, 0, leituraPadrao )

        }


        return retorno

      } )

    } )

  }

  const pesquisaDeterminacao = (): Promise<boolean> => {

    if ( idDeterminacao > 0 ) {

      const queryDeterminacao: string = `
        getDeterminacaoPorId (idDeterminacao: ${idDeterminacao}) {
          idDeterminacao
          nome
          simbolo
          idMaterial
          idTipoMapaProducao
          chave
          ordem
          parametros {
            descricao
            tipoInformacao
            conteudo
            variavel
          }
          leituraPadraoPorBandeja
          padraoAleatorioPorBandeja
          idUnidadeDeterminacao
          laboratorio
          laudo
          calculo {
            descricao
            tipoInformacao
            conteudo
            variavel
            conteudoInicial
          }
        }
      `

      return clsApi.query<DeterminacaoInterface>( queryDeterminacao, 'getDeterminacaoPorId', 'Carregando Determinacao...', contexto ).then( ( rsDeterminacao: DeterminacaoInterface ) => {

        return pesquisaUnidadeDeterminacao( rsDeterminacao.idUnidadeDeterminacao ).then( () => {

          incluirCabecalhoLeitura( rsDeterminacao )

          resetParametros( rsDeterminacao.parametros )

          setRsDeterminacao( rsDeterminacao )

          rsDeterminacaoInicial.current = rsDeterminacao

          return true

        } )

      } )

    } else {
      return Promise.resolve( false )
    }

  }

  const pesquisaUnidadeDeterminacao = ( idUnidadeDeterminacao: number ): Promise<true> => {

    const query: string = `
      getUnidadeDeterminacaoPorId (idUnidadeDeterminacao: ${idUnidadeDeterminacao}) {
        idUnidadeDeterminacao
        unidade
        casasDecimais
        tipoInformacao
        conversoes {
          unidade
          calculo
          casasDecimais
          tipoInformacao
          posCalculo
        }
      }
    `

    return clsApi.query<UnidadeDeterminacaoInterface>( query, 'getUnidadeDeterminacaoPorId', 'Carregando Unidade da Determinação...', contexto ).then( rsUnidadeDeterminacao => {
      setRsUnidadeDeterminacao( rsUnidadeDeterminacao )
      rsUnidadeDeterminacaoInicial.current = rsUnidadeDeterminacao.unidade
      return true
    } )

  }

  /**
   * Inclui no CABECALHO_INICIAL os campos editáveis (entradas, cálculos,etc...) da determinação
   * @param rsDeterminacao 
   * @returns 
   */
  const incluirCabecalhoLeitura = ( rsDeterminacao: DeterminacaoInterface ) => {

    let tmpCabecalho: Array<DataTableCabecalhoLeituraInterface> = []

    rsDeterminacao.calculo.forEach( calc => {
      tmpCabecalho.push( {
        cabecalho: calc.descricao,
        campo: calc.variavel,
        edit: calc.tipoInformacao === TipoInformacaoDeterminacaoType.ENTRADA,
        largura: LARGURA_MINIMA_INPUT_VALOR,
        calculo: calc,
        alinhamento: 'right',
        format: ( conteudo: string | number | boolean, rs: rsAmostraParaLeituraInterface ) => {

          if ( calc.tipoInformacao === TipoInformacaoDeterminacaoType.ENTRADA ) {

            if ( typeof conteudo === 'number' ) {

              const mascara: Array<string> = calc.conteudo.split( '.' )

              let casasDecimais: number = 0

              if ( mascara.length === 2 ) {
                casasDecimais = mascara[1].trim().length
              }

              return clsFormatacao.currency( conteudo, casasDecimais )

            } else {

              return typeof conteudo !== "undefined" ? conteudo.toString() : ''

            }

          } else {

            return typeof conteudo !== "undefined" ? conteudo.toString() : ''

          }

        }
      } )

      if ( calc.tipoInformacao === TipoInformacaoDeterminacaoType.ENTRADA && rsColunasEditaveis.current.findIndex( v => v === ( CABECALHO_INICIAL.length + tmpCabecalho.length ) - 1 ) < 0 ) {
        rsColunasEditaveis.current.push( ( CABECALHO_INICIAL.length + tmpCabecalho.length ) - 1 )
      }

    } )

    // Inclui Conversão da Unidade
    tmpCabecalho.push( {
      cabecalho: 'Unidade',
      campo: 'idUnidadeDeterminacao',
      edit: true,
      alinhamento: 'left'
    } )

    // Permite Edião da Unidade de Determinacao...
    // rsColunasEditaveis.current.push( ( CABECALHO_INICIAL.length + tmpCabecalho.length ) - 1 )

    // Inclui Botão de Edição no Final
    tmpCabecalho.push( {
      cabecalho: 'Salvar',
      campo: 'btSalvar',
      edit: false,
      alinhamento: 'center'
    } )

    setCabecalho( CABECALHO_INICIAL.concat( tmpCabecalho ) )

    return true

  }

  useEffect( () => {
    pesquisaDeterminacao().then( rs => {
      if ( rs ) {
        // console.log( 'Pesquisar Amostras....idMapaProducao: ', idMapaProducao, 'idDeterminacao: ', idDeterminacao )
        pesquisarAmostras()
      }
    } )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const resetParametros = ( parametros: Array<DeterminacaoCalculoInterface> ) => {

    let tmpRsParametros: Record<string, number> = {}

    parametros.forEach( rsParametro => {
      tmpRsParametros[rsParametro.variavel] = 0
    } )

    rsParametros.current = tmpRsParametros

  }

  const onAlterarParametro = ( propriedade: string, novoValor: number ) => {
    rsParametros.current[propriedade] = novoValor
  }

  const [exibirHelpTeclas, setExibirHelpTeclas] = useState<boolean>( false )

  return (
    <>

      <HelpTeclasLeiura exibir={exibirHelpTeclas} setExibir={setExibirHelpTeclas} />

      <LeituraParametros
        parametros={rsDeterminacao.parametros}
        nome={rsDeterminacao.nome}
        onAlterarParametro={( propriedade, novoValor ) => onAlterarParametro( propriedade, novoValor as number )}
        onClose={onClose}
      />

      <Grid item xs={12} sx={{ mt: 3, textAlign: 'right' }}>
        <IconButton aria-label="delete" onClick={() => setExibirHelpTeclas( true )}>
          <HelpIcon fontSize="inherit" />
        </IconButton>
      </Grid>

      <LeituraDataTable
        idMapaProducao={idMapaProducao}
        idUsuario={contexto.loginState.idUsuario}
        rsDeterminacao={rsDeterminacao}
        cabecalho={cabecalho}
        dados={rsAmostrasParaLeitura}
        rsParametros={rsParametros.current}
        onRefreshDados={( v ) => setRsAmostrasParaLeitura( [...v] )}
        rsColunasEditaveis={rsColunasEditaveis.current}
        rsUnidadeDeterminacao={rsUnidadeDeterminacao}
      />
      <ExibirJSONDev exibir={EMDESENVOLVIMENTO}
        oque={[
          'rsDeterminacao - Leitura.tsx', rsDeterminacao,
          'rsAmostrasParaLeitura', rsAmostrasParaLeitura,
          // 'rsUnidadeDeterminacao', rsUnidadeDeterminacao,
          // 'cabecalho', cabecalho,
          // 'rsParametros', rsParametros.current
        ]}
      />
    </>
  )

}